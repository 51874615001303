import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import IncentiveCatalog from './../../components/IncentiveCatalog/IncentiveCatalog';
import IncentiveSortControls from '../../client_customizations/components/IncentiveSortControls/IncentiveSortControls';
import IncentiveScoreOptions from '../../client_customizations/components/IncentiveScoreOptions/IncentiveScoreOptions';
import LoadingSpinner from './../../components/LoadingSpinner/LoadingSpinner';
import ModalComponent from '../../components/ModalUIComponent/ModalUIComponent';
import { FormattedMessage, useIntl } from 'react-intl';

import './Incentives.scss';

const Incentives = ({ electricVehicles, incentives, userLocation }) => {
  const intl = useIntl();
  useEffect(() => {
    document.title = process.env.REACT_APP_PAGES_INCENTIVES_TITLE;
  });

  const renderIncentives = incentives ? (
    <IncentiveCatalog incentives={incentives} category="all" main={true} />
  ) : (
    <LoadingSpinner />
  );
  const mobile = window.innerWidth < 720;

  const renderOptions = (mobile) =>
    electricVehicles && userLocation ? (
      <>
        {!mobile ? (
          <p className="score-options-header">
            <FormattedMessage
              id="evs.incentivesFilterHeader"
              defaultMessage="Eligibility Filters"
              description="Eligibility Filters"
            />
          </p>
        ) : null}
        <IncentiveScoreOptions
          userLocation={userLocation}
          electricVehicles={electricVehicles}
          mobile={mobile}
        />
      </>
    ) : null;

  return (
    <>
      <section className="container-fluid" id="incentives">
        <div className="container">
          <div className="row mb-3">
            <div className="col-sm-12 text-center">
              <h1 className="evHead">
                <FormattedMessage
                  id="incentives.title"
                  defaultMessage="Electric vehicle incentives"
                  description="Electric vehicle incentives"
                />
              </h1>
              <p className="lead">
                <FormattedMessage
                  id="incentives.subTitle"
                  defaultMessage="You may be eligible for a range of incentives, including rebates, tax credits, and various other benefits. Adjust the filters to personalize your results."
                  description="Description of Platte River"
                  values={{
                    lineBreak: <br />,
                  }}
                />
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="pull-right">
                <IncentiveSortControls mobile={mobile} />
              </div>
            </div>
          </div>
          <div className="row main-container">
            <div
              className="col-xl-3 col-lg-4 d-block"
              style={mobile ? { marginTop: '.5rem' } : { marginTop: '0' }}
            >
              {!mobile ? (
                renderOptions(false)
              ) : (
                <ModalComponent
                  buttonText={
                    intl.formatMessage
                      ? intl.formatMessage({
                          id: 'ev.filters.title',
                          defaultMessage: 'Open Filters',
                        })
                      : 'Open Filters'
                  }
                  titleText={
                    intl.formatMessage
                      ? intl.formatMessage({
                          id: 'incentive.filters.title',
                          defaultMessage: 'Personalize Incentives',
                        })
                      : 'Personalize Incentives'
                  }
                  submitText={
                    intl.formatMessage
                      ? intl.formatMessage({
                          id: 'apply',
                          defaultMessage: 'Apply',
                        })
                      : 'Apply'
                  }
                  disclaimerText={
                    <FormattedMessage
                      id="incentives.disclaimer"
                      defaultMessage="Incentive eligibility and amounts do not constitute tax or legal
              advice. Consult the incentive grantor and/or your tax or legal
              professional to determine eligibility, specific amount of
              incentives or rebates available."
                      description="Electric vehicle incentives"
                    />
                  }
                >
                  {renderOptions(true)}
                </ModalComponent>
              )}
            </div>
            <div
              className="col-xl-8 col-lg-8 col-sm-8"
              style={{ paddingLeft: 10 }}
            >
              {renderIncentives}
            </div>
          </div>
          {!mobile ? (
            <p className="disclaimer">
              <FormattedMessage
                id="incentives.disclaimer"
                defaultMessage="Incentive eligibility and amounts do not constitute tax or legal
              advice. Consult the incentive grantor and/or your tax or legal
              professional to determine eligibility, specific amount of
              incentives or rebates available."
                description="Electric vehicle incentives"
              />
            </p>
          ) : null}
        </div>
      </section>
    </>
  );
};

export default Incentives;

Incentives.propTypes = {
  incentives: PropTypes.array,
  electricVehicles: PropTypes.array,
  zip: PropTypes.string,
  ip: PropTypes.string,
  uuid: PropTypes.string,
};
