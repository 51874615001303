import React from 'react';
import PropTypes from 'prop-types';
import { FormatCarName, FormatAsInt } from '../../utils/Helpers/Format';
import 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import { FormattedMessage, useIntl } from 'react-intl';

const ElectricRangeGraph = ({ cars, hasExtraPadding }) => {
  const intl = useIntl();

  if (!cars || cars.length === 0) return null;

  cars = cars.filter((car) => {
    return car !== null;
  });

  cars = cars.map((car) => {
    if (car.electric_range === 'N/A') {
      car.electric_range = 0;
    }
    car['gasoline_range'] = car.total_range - car.electric_range;
    return car;
  });

  const CHART_JS_OPTIONS = {
    maintainAspectRatio: false,
    tooltips: {
      yAlign: 'center',
      callbacks: {
        label: function (tooltipItem, data) {
          var value =
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          var label = data.datasets[tooltipItem.datasetIndex].label || '';
          value = FormatAsInt(value);
          return ' ' + label + ': ' + value;
        },
        title: function (tooltipItems, data) {
          let total = tooltipItems.reduce(function (acc, item) {
            return acc + item.xLabel;
          }, 0);
          return tooltipItems[0].yLabel + ': ' + FormatAsInt(total);
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          callback: function (value) {
            return value;
          },
          beginAtZero: true,
        },
        scaleLabel: {
          display: true,
          labelString: intl.formatMessage({
            id: 'vehicle.miles',
            defaultMessage: 'miles',
          }),
          fontSize: 10,
          fontColor: '#2b2b2b',
        },
      },
      y: {
        stacked: true,
        ticks: {
          font: {
            weight: 700,
          },
        },
      },
    },
    layout: {
      padding: {
        right: 25,
        top: 25,
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
        onClick: function (e) {
          e.stopPropagation();
        },
        labels: {
          filter: function (item) {
            return !item.text.includes('hidden');
          },
        },
      },
      datalabels: {
        formatter: (value, ctx) => {
          let datasets = ctx.chart.data.datasets;
          if (ctx.datasetIndex === datasets.length - 1) {
            let sum = 0;
            datasets.forEach((dataset) => {
              sum += dataset.data[ctx.dataIndex];
            });
            return `${FormatAsInt(sum)} ${intl.formatMessage({
              id: 'vehicle.miles',
              defaultMessage: 'miles',
            })}`;
          } else if (value === 0) {
            return '';
          } else {
            return ctx.dataset.abbreviatedLabel;
          }
        },
        align: 'end',
        anchor: 'end',
        color: '#333333',
        font: {
          weight: 500,
        },
      },
    },
  };

  const sortedCarRange = [...cars].sort((car1, car2) => {
    return car2.electric_range - car1.electric_range;
  });

  const subTitle = (cars) => {
    if (cars.length === 2) {
      const costDelta = cars[1].electric_range - cars[0].electric_range;
      const costDeltaText =
        costDelta < 0
          ? intl.formatMessage({
              id: 'vehicle.greater',
              defaultMessage: 'greater',
            })
          : intl.formatMessage({
              id: 'vehicle.fewer',
              defaultMessage: 'fewer',
            });

      return (
        <p className="h3 my-3 graph-sub-title">
          <FormattedMessage
            id="graph.electricRange.subTitle"
            defaultMessage="The {car}'s electric range is {costDeltaText} by {number} miles"
            description="Electric Range Sub Title Two Cars"
            values={{
              car: FormatCarName(cars[0]),
              number: Math.abs(costDelta),
              costDeltaText: costDeltaText,
            }}
          />
        </p>
      );
    } else {
      return (
        <p className="h3 my-3 graph-sub-title">
          <FormattedMessage
            id="graph.electricRange.subTitleThreeCars"
            defaultMessage="The {car} has the longest Electric Range"
            description="Electric Range Sub Title Three Cars"
            values={{
              car: FormatCarName(sortedCarRange[0]),
            }}
          />
        </p>
      );
    }
  };

  const title = <>{subTitle(cars)}</>;

  const carNames = cars.map((car) => FormatCarName(car));

  const chartData = {
    labels: [[carNames[0]], [carNames[1]], [carNames[2]]],
    datasets: [
      {
        label: intl.formatMessage({
          id: 'graph.title.electricRange',
          defaultMessage: 'Electric Range',
        }),
        backgroundColor: `#${process.env.REACT_APP_COMPONENTS_COST_TO_OWN_COMPARISION_CHART_BAR_COLOR_ELECTRICITY}`,
        data: cars.map((car) => car.electric_range),
        abbreviatedLabel: '',
      },
      {
        label: intl.formatMessage({
          id: 'graph.electricRange.gasolineRange',
          defaultMessage: 'Gasoline Range',
        }),
        backgroundColor: `#${process.env.REACT_APP_COMPONENTS_COST_TO_OWN_COMPARISION_CHART_BAR_COLOR_GASOLINE}`,
        data: cars.map((car) => car.gasoline_range),
        abbreviatedLabel: '',
      }
    ],
  };

  return (
    <div
      className="input-well text-center"
      style={hasExtraPadding ? { padding: '2.5rem' } : { paddfing: '1rem' }}
    >
      {title}
      <div>
        <div>
          <Bar
            data={chartData}
            height={512}
            type="verticalBar"
            options={CHART_JS_OPTIONS}
          />
        </div>
      </div>
      {hasExtraPadding && <br />}
    </div>
  );
};

export default ElectricRangeGraph;

ElectricRangeGraph.propTypes = {
  cars: PropTypes.array,
  hasExtraPadding: PropTypes.bool,
};
